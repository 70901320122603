<template>
    <div class="card-basic contact-block" v-if="kontaktperson!='' || telefonnummer!='' || eMail!=''">
        <h5 class="post-heading">Kontakt</h5>
        <ul class="contact">
            <li class="contact-item do-not-translate" v-if="kontaktperson!==null">
                <span class="material-icons-outlined icon" aria-hidden="true">person</span>
                <span class="text">{{kontaktperson}}</span>
            </li>
            <li class="contact-item" v-if="telefonnummer!==null">
                <span class="material-icons-outlined icon" aria-hidden="true">phone</span>
                <span class="text">{{telefonnummer}}</span>
            </li>
            <li class="contact-item" v-if="eMail!==null">
                <span class="material-icons-outlined icon" aria-hidden="true">mail</span>
                <a :href="'mailto:'+eMail" :alt="'E-Mail senden an'+eMail" class="text">{{eMail}}</a>
            </li>
        </ul>

        <form action="#!">
            <p class="text-black mb20 font14">Oder schicke eine Nachricht über unser Formular:</p>
            <div class="form-group">
                <input type="text" class="form-control" v-model="data.sender" placeholder="Deine Email Adresse">
                <div ref="email-help" class="help" v-show="errors.sender">Geben Sie eine gültige E-Mail Adresse an</div>
            </div>
            <div class="form-group">
                <textarea class="form-control" v-model="data.message" cols="30" rows="10" placeholder="Deine Nachricht"></textarea>
                <div ref="message-help" class="help" v-show="errors.message">Geben Sie eine Nachricht ein</div>
            </div>
            <div class="form-check form-check-inline">
                <div>
                    Wenn Sie die Nachricht absenden, kontaktieren Sie den Anbieter direkt. Hinweise zur Datenverarbeitung des Anbieters können Sie der jeweiligen Datenschutzerklärung des Anbieters entnehmen.
                </div>
            </div>
            <button @click="sendMessage" class="btn btn-block btn-secondary mt30">Nachricht senden</button>
        </form>
    </div>
</template>

<script>
    import { sendMail } from '@/api/email';
    import { validEmail } from '@/utils/validate';
    import { getFieldValues, hasErrors } from '@/utils/helpers';

    export default {
        name: 'ContactBlock',
        props: {
            contact: {
                type: Object,
                required: true,
                default: {}
            },
            contentId: {
                type: Number,
                required: true,
                default: null
            },
        },
        data() {
            return {
                data: {
                    email_id: 17,
                    message: '',
                    content_id: undefined,
                    sender: '',
                    policies: false,
                    link: window.location.href,
                    app: this.$appName
                },
                errors: {
                    datenschutz: false,
                    sender: false,
                    message: false
                }
            }
        },
        created() {
            this.data.content_id = this.contentId;
        },
        computed: {
            kontaktperson(){
                var value = getFieldValues(this.contact,'kontaktperson');
                return value!=null&&value!==""?value:null;
            },
            telefonnummer(){
                var value = getFieldValues(this.contact,'telefonnummer');
                return value!=null&&value!==""?value:null;
            },
            eMail(){
                var value = getFieldValues(this.contact,'e-mail_adresse');
                return value!=null&&value!==""?value:null;
            },
        },
        methods:{
            getFieldValues,
            sendMessage: function (event){
                event.preventDefault();

                if(this.data.sender == "" || !validEmail(this.data.sender)){
                    this.errors.sender = true;
                }
                else{
                    this.errors.sender = false;
                }

                if(this.data.message==""){
                    this.errors.message = true;
                }
                else{
                    this.errors.message = false;
                }

                if(this.data.datenschutz==false){
                    this.errors.datenschutz = true;
                }
                else{
                    this.errors.datenschutz = false;
                }

                if(!hasErrors(this.errors)){
                    sendMail(this.data).then( response => {
                        this.$notify({
                            duration: 3500,
                            title: 'E-Mail gesendet',
                            text: 'Ihre E-Mail wurde versendet!'
                        });

                        this.data.sender = "";
                        this.data.message = "";
                        this.data.datenschutz = false;

                    }).catch(() => {
                        this.$notify({
                            type: 'error',
                            duration: 3500,
                            title: 'Feher!',
                            text: 'Es ist ein Fehler aufgetreten. Ihre E-Mail konnte nicht gesendet werden.'
                        });
                    });
                }

            },

        }
    }
</script>
<style lang="scss" scoped>
    @import '@/scss/_mixins.scss';
    @import '@/scss/_variables.scss';

    .contact-block{

        .form-group, .form-check{
            display: block;

            .form-control{
                display: block;
            }

            .help{
                color: red;
                display: block;
                margin-top: 2px;
            }
        }

        .contact{
            @media (max-width: 991px) {
                margin-left: 0px;
            }

            @media (max-width: 1200px) {
                flex-direction: column;
            }

            li{
                margin-right:20px;

                &:last-child{
                    margin-right:0px;
                }
            }
        }
    }

</style>